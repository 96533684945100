import { CABIN_BAGGAGE } from '../../../products';
// eslint-disable-next-line import/no-self-import
import * as CabinBaggageUtil from './CabinBaggageUtil';
export const BAGGAGE_STATUS = {
  AVAILABLE: 'Available',
  INCLUDED: 'Included',
  NOT_INCLUDED: 'Not Included'
};
export const getTravelersPrimaryFlow = order => {
  var _order$sellSpecificat, _order$sellSpecificat2;
  const baggageSpecification = (_order$sellSpecificat = (_order$sellSpecificat2 = order.sellSpecification) === null || _order$sellSpecificat2 === void 0 ? void 0 : _order$sellSpecificat2.sellPriceTravelers) !== null && _order$sellSpecificat !== void 0 ? _order$sellSpecificat : [];
  return order.travelers.map(traveler => {
    var _price$markup$value, _price$markup, _price$price;
    const {
      price
    } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      id: (traveler === null || traveler === void 0 ? void 0 : traveler.id) || '',
      markup: (_price$markup$value = price === null || price === void 0 || (_price$markup = price.markup) === null || _price$markup === void 0 ? void 0 : _price$markup.value) !== null && _price$markup$value !== void 0 ? _price$markup$value : null,
      price: price === null || price === void 0 || (_price$price = price.price) === null || _price$price === void 0 ? void 0 : _price$price.value
    };
  });
};
export const getTravelersPostbooking = (productId, order) => {
  var _order$availableExtra;
  const baggageProduct = (_order$availableExtra = order.availableExtraProducts) === null || _order$availableExtra === void 0 ? void 0 : _order$availableExtra.find(product => String(product === null || product === void 0 ? void 0 : product.id) === String(productId));
  const baggageSpecifications = baggageProduct !== null && baggageProduct !== void 0 && baggageProduct.sellSpecification && 'sellPriceTravelers' in baggageProduct.sellSpecification ? baggageProduct.sellSpecification.sellPriceTravelers : [];
  return order.travelers.map(traveler => {
    var _price$markup$value2, _price$markup2, _price$price2;
    const {
      price
    } = (baggageSpecifications === null || baggageSpecifications === void 0 ? void 0 : baggageSpecifications.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      name: traveler.fullName || '',
      markup: (_price$markup$value2 = price === null || price === void 0 || (_price$markup2 = price.markup) === null || _price$markup2 === void 0 ? void 0 : _price$markup2.value) !== null && _price$markup$value2 !== void 0 ? _price$markup$value2 : null,
      price: price === null || price === void 0 || (_price$price2 = price.price) === null || _price$price2 === void 0 ? void 0 : _price$price2.value
    };
  });
};
export const getTripSegments = bound => bound.segments.filter(segment => segment.__typename === 'TripSegment');
export const bundleContentDetail = bundleData => {
  var _bundleData$options, _bundleContents$conte;
  const bundleContents = bundleData === null || bundleData === void 0 || (_bundleData$options = bundleData.options) === null || _bundleData$options === void 0 ? void 0 : _bundleData$options.find(option => (option === null || option === void 0 ? void 0 : option.id) !== undefined && option.id === bundleData.selectedOption);
  return bundleContents === null || bundleContents === void 0 || (_bundleContents$conte = bundleContents.content) === null || _bundleContents$conte === void 0 ? void 0 : _bundleContents$conte.find(bundleContent => (bundleContent === null || bundleContent === void 0 ? void 0 : bundleContent.productTypeId) === CABIN_BAGGAGE.id.toString());
};
export const cabinBaggageDescription = _ref => {
  let {
    weight,
    weightUnit,
    dimensions,
    pieces,
    t
  } = _ref;
  const weightDescription = weight && weightUnit ? t('Product.BaggagePerTraveler.Weight', weight, weightUnit) : '';
  const dimensionsDescription = dimensions ? t('Product.CabinBaggagePerTraveler.Dimensions', dimensions === null || dimensions === void 0 ? void 0 : dimensions.length, dimensions === null || dimensions === void 0 ? void 0 : dimensions.width, dimensions === null || dimensions === void 0 ? void 0 : dimensions.height) : '';
  if (!weightDescription) {
    var _ref2;
    const unAvailableWeightLabel = t('Product.CabinBaggagePerTraveler.Unavailable.Weight.Item.Label');
    return (_ref2 = `${pieces} ${unAvailableWeightLabel} ${dimensionsDescription}`) === null || _ref2 === void 0 ? void 0 : _ref2.trim();
  }
  const piecesDescription = pieces ? `${t('Product.BaggagePerTraveler.Pieces', pieces)} x` : '';
  const pieceWeightAndDimensionsText = `${piecesDescription} ${weightDescription} ${dimensionsDescription}`;
  return pieceWeightAndDimensionsText.trim();
};
export const cabinBaggageBundlePiecesWeightDimension = bundleData => {
  const bundleCabinBaggageDetails = bundleContentDetail(bundleData);
  const {
    pieces,
    weight,
    weightUnit,
    size3d
  } = bundleCabinBaggageDetails || {};
  return {
    pieces: pieces !== null && pieces !== void 0 ? pieces : undefined,
    weight: weight !== null && weight !== void 0 ? weight : undefined,
    weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : undefined,
    dimensions: size3d !== null && size3d !== void 0 ? size3d : undefined
  };
};
export const cabinBaggageIncludedPiecesWeight = includedBaggage => {
  const {
    pieces,
    weight,
    weightUnit,
    size3d
  } = includedBaggage !== null && includedBaggage !== void 0 ? includedBaggage : {};
  return {
    pieces,
    weight: weight !== null && weight !== void 0 ? weight : undefined,
    weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : undefined,
    dimensions: size3d !== null && size3d !== void 0 ? size3d : undefined
  };
};
export const cabinBaggagePurchasablePiecesWeightDimension = sellSpecification => {
  const {
    pieces,
    weight,
    weightUnit,
    size3d
  } = sellSpecification !== null && sellSpecification !== void 0 ? sellSpecification : {};
  return {
    pieces,
    weight: weight !== null && weight !== void 0 ? weight : undefined,
    weightUnit: weightUnit !== null && weightUnit !== void 0 ? weightUnit : undefined,
    dimensions: size3d !== null && size3d !== void 0 ? size3d : undefined
  };
};
export const cabinBagDetails = _ref3 => {
  let {
    sellSpecification,
    bundleData,
    includedBaggage
  } = _ref3;
  const isCabinBaggageBundle = CabinBaggageUtil.bundleContentDetail(bundleData);
  if (isCabinBaggageBundle) {
    return CabinBaggageUtil.cabinBaggageBundlePiecesWeightDimension(bundleData);
  }
  if (includedBaggage) {
    return CabinBaggageUtil.cabinBaggageIncludedPiecesWeight(includedBaggage);
  }
  return CabinBaggageUtil.cabinBaggagePurchasablePiecesWeightDimension(sellSpecification);
};