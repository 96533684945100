import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useProperty } from '@eti/providers';
import { PaymentResponseType } from '@eti/schema-types';
import { css } from '@eti/styles';
import { breakpoints } from '@etg/wings';
import { routes } from '../../../constants/routesAndApisConstants';
import { CANCEL_MAKE_ORDER_MUTATION } from '../../payment/graphQL/mutations';
import { CFC_REDIRECT_URL } from '../../payment/constants/sessionStorage';
import ErrorPage from '../../status-pages/components/ErrorPage';
import { CONTINUE_MAKE_ORDER } from '../graphQL/mutations';
import CFCTimeoutModal from './CFCTimeoutModal';

const iFrameWrapperStyles = css`
  display: flex;
  justify-content: center;
`;

const iFrameStyles = css`
  height: 100vh;
  margin: auto;
  width: 1028px;

  @media (max-width: ${breakpoints._768}) {
    width: 768px;
  }
`;

const CFCIframePage = () => {
  const history = useHistory();
  const { p } = useProperty();
  const isCFCEnabled = p('IbeClient.CarrierFlightConfirmation.Enabled');
  const isOrderStatusPageEnabled = p('OrderStatusPage.Enabled');
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [isCFCTimeoutModalOpen, setIsCFCTimeoutModalOpen] = useState(false);
  const confirmationURL = sessionStorage.getItem(CFC_REDIRECT_URL) || '';

  const handleProcessingOrder = () => {
    const processingOrderRoute = isOrderStatusPageEnabled
      ? routes.ORDER_STATUS
      : routes.PROCESSING_ORDER;
    history.push(processingOrderRoute);
  };

  const handleReceipt = () => {
    const receiptRoute = isOrderStatusPageEnabled ? routes.ORDER_STATUS : routes.ORDER;
    history.push(receiptRoute);
  };

  const [continueMakeOrderMutation] = useMutation(CONTINUE_MAKE_ORDER, {
    onCompleted: (data) => {
      if (data.continueMakeOrder.type === PaymentResponseType.PaymentPageWithMessage) {
        setShowErrorPage(true);
      }

      if (data.continueMakeOrder.type === PaymentResponseType.ProcessingOrder) {
        handleProcessingOrder();
      }

      if (data.continueMakeOrder.type === PaymentResponseType.Receipt) {
        handleReceipt();
      }
    },
  });

  const [cancelMakeOrderMutation] = useMutation(CANCEL_MAKE_ORDER_MUTATION);

  const handleRyanairTelemetryEvent = useCallback(
    async (state: string, status?: number) => {
      if (state === 'REDIRECT' && status === 200) {
        await continueMakeOrderMutation();
      }

      if (state === 'SESSION_ERROR') {
        await cancelMakeOrderMutation()
          .then(() => setShowErrorPage(true))
          .catch((e) => {
            // eslint-disable-next-line no-console
            console.error(e);
          });
      }

      if (state === 'TIMEOUT' || (state === 'REDIRECT' && status === 440)) {
        setIsCFCTimeoutModalOpen(true);
      }
    },
    [cancelMakeOrderMutation, continueMakeOrderMutation],
  );

  useEffect(() => {
    const eventListener = (message: MessageEvent<any>) => {
      if (message.data.type === 'RYANAIR_TELEMETRY') {
        console.log('ryanairMessage:', message); // eslint-disable-line no-console
        handleRyanairTelemetryEvent(message.data.payload.state, message.data.payload.status);
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, [handleRyanairTelemetryEvent]);

  return !showErrorPage && isCFCEnabled && confirmationURL ? (
    <div className={iFrameWrapperStyles}>
      {/* TODO: Revisit iframe title with translation for accessibility reasons */}
      <iframe
        className={iFrameStyles}
        data-testid="cfc-iframe"
        src={confirmationURL}
        title="carrier flight confirmation"
      />
      <CFCTimeoutModal
        cancelMakeOrderMutation={cancelMakeOrderMutation}
        isOpen={isCFCTimeoutModalOpen}
        setIsOpen={setIsCFCTimeoutModalOpen}
      />
    </div>
  ) : (
    <ErrorPage />
  );
};

export default CFCIframePage;
